<template>
  <v-container
    fluid
    class="pa-2 pt-0"
  >
    <v-card
      class="py-6 px-6 mt-3 font-inter"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <v-row>
        <v-col flex>
          <div
            class="back-to-projects"
            @click="handleBackClick"
          >
            <v-icon color="blue">
              mdi-chevron-left
            </v-icon>
            <p class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold">
              {{ $t('milestone.create_milestone.back_to_milestones') }}
            </p>
          </div>
        </v-col>
        <v-col
          v-if="showAddUsersView"
          cols="auto"
          class="pointer"
          @click="closeShowAddUsersView"
        >
          <v-icon>mdi-close</v-icon>
        </v-col>
      </v-row>
      <template v-if="showAddUsersView">
        <p class="text-start display-1 font-weight-bold my-2">
          {{ $t('projectUsers') }}
        </p>
        <div class="mt-6 d-flex">
          <v-chip
            :class="{ 'blue--text': tableFilter === 'all' }"
            width="200px"
            :color="tableFilter === 'all' ? 'blue-light' : 'gray-light'"
            label
            @click="changeFilter('all')"
          >
            <div class="font-weight-bold px-2">
              {{ $t('all') }} <span class="ml-2">{{ users.length - selectedUserLength }}</span>
            </div>
          </v-chip>
          <div class="ml-2">
            <v-chip
              :class="{ 'blue--text': tableFilter === 'selected' }"
              width="200px"
              :color="tableFilter === 'selected' ? 'blue-light' : 'gray-light'"
              label
              @click="changeFilter('selected')"
            >
              <div class="font-weight-bold px-2">
                {{ $t('selected') }} <span class="ml-2">{{ selectedUserLength }}</span>
              </div>
            </v-chip>
          </div>
        </div>
        <v-row class="d-flex align-center justify-space-between mt-5">
          <v-col
            :cols="$vuetify.breakpoint.smAndUp ? 'auto' : 12"
            class="d-flex align-center"
          >
            <v-text-field
              v-model="search"
              type="text"
              color="blue"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search by name, email, etc."
              class="search_input"
              dense
              single-line
              filled
              hide-details
              clear-icon="body-2"
            />
          </v-col>
          <v-col
            flex
            class="d-flex justify-space-between align-center"
          >
            <MilestoneFilter @filters="applyFilters" />
            <v-sheet
              color="gray-100"
              width="50px"
              class="d-flex ml-4 align-center justify-center"
              height="50px"
              rounded="lg"
              v-on="on"
            >
              <v-icon color="gray-ish">
                mdi-cog-outline
              </v-icon>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row
          v-if="isAnyFilterApplied"
          class="mt-3"
        >
          <v-chip
            v-for="role in this.appliedFilters.roles"
            :key="role.id"
            color="gray-light"
            class="ma-2 gray-ish--text font-weight-semi-bold"
            label
          >
            {{ $t('role') }}: {{ role.name }}
          </v-chip>
          <v-chip
            v-for="project in this.appliedFilters.projects"
            :key="project.id"
            color="gray-light"
            class="ma-2 gray-ish--text font-weight-semi-bold"
            label
          >
            {{ $t('project') }}: {{ project.name }}
          </v-chip>
          <v-chip
            v-for="tag in this.appliedFilters.tags"
            :key="tag.id"
            color="gray-light"
            class="ma-2 gray-ish--text font-weight-semi-bold"
            label
          >
            {{ $t('project') }}: {{ tag.title }}
          </v-chip>
          <v-btn
            class="text-capitalize mt-1"
            color="blue"
            text
            @click="clearAll"
          >
            {{ $t('clearAll') }}
          </v-btn>
        </v-row>
        <MilestoneTable
          :users-data="users"
          :table-filter="tableFilter"
          @updateUser="updateUser"
        />
      </template>
      <template v-else>
        <v-row justify="center">
          <v-col
            md="5"
            lg="4"
            class="pa-0 pb-12"
          >
            <h2 class="text-start">
              {{ $t('milestone.create_milestone.title') }}
            </h2>
          </v-col>
        </v-row>
        <v-form
          ref="milestoneForm"
          role="createMilestone"
          @submit.prevent="handleSubmit(updateProfile)"
        >
          <v-row justify="center">
            <v-col
              md="5"
              lg="4"
              pa="0"
              class="pa-0"
            >
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
                {{ $t('milestone.create_milestone.milestone_name') }}
                <strong class="red--text text--lighten-1">*</strong>
              </p>
              <v-text-field
                v-model="form.name"
                type="text"
                dense
                single-line
                filled
                placeholder="Name"
                :rules="requiredRule"
                class="rounded-lg"
                clear-icon="body-2"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              md="5"
              lg="4"
              class="pa-0"
            >
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
                {{ $t('milestone.create_milestone.startDate') }}
                <strong class="red--text text--lighten-1">*</strong>
              </p>
              <v-menu
                v-model="form.startDate"
                :close-on-content-click="false"
                full-width
                max-width="290"
              >
                <template 
                  v-slot:activator="{ on }" 
                >
                  <div class="calendar-textbox-container">
                    <v-text-field
                      dense
                      single-line
                      filled
                      color="transparent"
                      class="mr-0 custom_input rounded-lg calendar-textbox"
                      :value="startDate"
                      :rules="requiredRule"
                      placeholder="MM/DD/YY"
                      readonly
                      v-on="on"
                    />
                    <calendarBlueIcon 
                      class="calendar-icon"  
                      v-on="on"   
                    />
                  </div>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @change="form.startDate = null"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              md="5"
              lg="4"
              class="pa-0"
            >
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
                {{ $t('milestone.create_milestone.dueDate') }}
                <strong class="red--text text--lighten-1">*</strong>
              </p>
              <v-menu
                v-model="form.dueDate"
                :close-on-content-click="false"
                full-width
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <div class="calendar-textbox-container">
                    <v-text-field
                      dense
                      single-line
                      filled
                      color="blue"
                      class="mr-0 custom_input rounded-lg calendar-textbox"
                      :value="dueDate"
                      placeholder="MM/DD/YY"
                      readonly
                      :rules="[...requiredRule,...dueDateRule]"
                      v-on="on"
                    />
                    <calendarBlueIcon 
                      class="calendar-icon"  
                      v-on="on"   
                    />
                  </div>
                </template>
                <v-date-picker
                  v-model="dueDate"
                  @change="form.dueDate = null"
                />
              </v-menu>
            </v-col>
          </v-row>
                 
          <v-row justify="center">
            <v-col
              md="5"
              lg="4"
              class="pa-0"
            >
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
                {{ $t('milestone.create_milestone.status') }}
              </p>
              <v-combobox
                v-model="form.status"
                type="text"
                dense
                single-line
                filled
                placeholder="Status"
                :items="statuses"
                class="rounded-lg"
                clear-icon="body-2"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              md="5"
              lg="4"
              class="pa-0"
            >
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
                {{ $t('milestone.create_milestone.description') }}
              </p>
              <v-text-field
                v-model="form.description"
                type="text"
                dense
                single-line
                filled
                placeholder="Description"
                hide-details
                class="rounded-lg"
                clear-icon="body-2"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
      <v-row
        justify="end"
        class="mt-4"
      >
        <v-col cols="12">
          <v-flex class="mt-6 d-sm-flex justify-end">
            <v-menu
              v-model="menuOpen"
              :close-on-content-click="false"
              offset-y
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="!showAddUsersView"
                  v-bind="attrs"
                  dark
                  large
                  color="gray-100"
                  class="text-capitalize font-weight-bold black--text mr-4 mt-2 box-shadow-none"
                  :width="$vuetify.breakpoint.smAndDown ? '100%' : '200px'"
                  v-on="on"
                >
                  <v-icon>{{ 'mdi-plus' }}</v-icon> {{ $t('milestone.create_milestone.addTestActivities') }} 
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="addTestRun">
                  <v-list-item-title>{{ $t('milestone.create_milestone.addTestRuns') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="addTestPlans">
                  <v-list-item-title>{{ $t('milestone.create_milestone.addTestPlans') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              v-if="!showAddUsersView || (showAddUsersView && !toBeSelectedUserLength)"
              dark
              large
              color="blue"
              class="text-capitalize font-weight-bold mt-2 box-shadow-none"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '200px'"
              :disabled="isCreateDisabled"
              @click="handleCreateMilestone"
            >
              {{ $t('milestone.createMilestone') }}
            </v-btn>
          </v-flex>
        </v-col>
      </v-row>
    </v-card>
    <MilestoneDiscardDialog
      v-model="showConfirmBackDialog"
      :title="$t('milestone.create_milestone.close_dialog.title')"
      :content="$t('milestone.create_milestone.close_dialog.content')"
      :btn_label="$t('milestone.create_milestone.close_dialog.confirm_button')"
      @close="handleCloseClick"
      @handleConfirmClick="handleConfirmClick"
    />
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import MilestoneDiscardDialog from '@/components/Milestone/MilestoneDiscardDialog.vue';
import MilestoneTable from '@/components/Milestone/MilestoneTable.vue';
import MilestoneFilter from '@/components/Milestone/MilestoneFilter.vue';
import { users } from '@/constants/data.js';
import { showSuccessToast, showAlertToast, showErrorToast } from '@/utils/toast';
import calendarBlueIcon from '@/assets/svg/calendar-blue.svg';
const { mapActions } = createNamespacedHelpers('milestone');
const { mapState } = createNamespacedHelpers('user');

export default {
  name: 'MilestoneCreate',
  components: {
    MilestoneDiscardDialog,
    MilestoneTable,
    MilestoneFilter,
    calendarBlueIcon
  },
  data() {
    return {
      requiredRule: [v => !!v || this.$t('thisFieldIsRequired')],
      dueDateRule: [ v => new Date(v) > new Date(this.startDate) || this.$t('milestone.create_milestone.dueDateError')],
      isCreateDisabled: false,
      imageSrc: '',
      users: users,
      form: {
        name: '',
        description: '',
        status: '',
        users: [],
        startDate: null,
        dueDate: null,
      },
      startDate: '',
      dueDate: '',
      menuOpen: false,
      showConfirmBackDialog: false,
      showAddUsersView: false,
      toBeSelectedUser: [],
      selectedUser: [],
      tableFilter: 'all',
      search: '',
      appliedFilters: {
        roles: [],
        projects: [],
        tags: [],
      },
      statuses: ['Active', 'Upcoming', 'Blocked'],
    };
  },
  computed: {
    ...mapState(['currentAccount']),
    imageStyle() {
      return this.imageSrc ? { backgroundImage: `url(${this.imageSrc})` } : {};
    },
    toBeSelectedUserLength() {
      return this.users.filter((user) => user.toBeSelected).length;
    },
    selectedUserLength() {
      return this.users.filter((user) => user.selected).length;
    },
    isAnyFilterApplied() {
      return this.appliedFilters.roles.length || this.appliedFilters.projects.length || this.appliedFilters.tags.length;
    },
  },
  mounted() {
    this.users.forEach((user) => {
      this.$set(user, 'toBeSelected', false);
    });
  },
  methods: {
    ...mapActions(['add']),
    handleCreateMilestone() {
      if (!this.$refs.milestoneForm.validate()) return;
      this.isCreateDisabled = true;
      const payload = {
        name: this.form.name,
        dueDate: new Date(this.dueDate),
        customFields: {
          startDate: new Date(this.startDate),
          status: this.form.status,
          description: this.form.description,
          runstatus: 'open',
          progress: 30,
          testRuns: [],
          testCases: [],
        },
        externalId: 'testfiesta',
        source: 'testfiesta-web',
      };

      this.add({ swal: this.$swal, handle: this.$route.params.handle, projectKey: this.$route.params.key, payload })
        .then(() => {
          showSuccessToast(this.$swal, this.$t('milestone.create_milestone_success'));
          this.$router.push({
            name: 'Milestones',
            params: {
              handle: this.$route.params.handle,
              key: this.$route.params.key
            },
          });
        })
        .catch((error) => {
          this.isCreateDisabled = false;
          showErrorToast(this.$t('error.milestoneCreateFailed'));
          console.error('Failed to create milestone:', error);
        });
    },
    addTestRun() {
      const payload = {
        name: this.form.name,
        dueDate: new Date(this.dueDate),
        customFields: {
          startDate: new Date(this.startDate),
          status: this.form.status,
          description: this.form.description,
          runstatus: 'open',
          progress: 30,
          testRuns: [],
          testCases: [],
        },
        externalId: 'testfiesta',
        source: 'testfiesta-web',
      };
      localStorage.setItem('create_data', JSON.stringify(payload));
      this.$router.push({
        name: 'MilestoneTestRun',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        },
      }); // Navigate to the desired route
    },
    addTestPlans() {
      const payload = {
        name: this.form.name,
        dueDate: new Date(this.dueDate),
        customFields: {
          startDate: this.startDate,
          status: this.form.status,
          description: this.form.description,
          runstatus: 'open',
          progress: 30,
          testRuns: [],
          testCases: [],
        },
        externalId: 'testfiesta',
        source: 'testfiesta-web',
      };
      localStorage.setItem('create_data', JSON.stringify(payload));
      this.$router.push({
        name: 'MilestoneTestPlan',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        },
      });
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.previewImage(file);
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.previewImage(file);
    },
    previewImage(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    removeImage() {
      this.imageSrc = '';
    },
    handleBackClick() {
      if (this.form.name || this.form.startDate || this.form.dueDate || this.form.status || this.form.description) {
        this.showConfirmBackDialog = true;
        return;
      }

      this.$router.replace({
        name: 'Milestones',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        },
      });
    },
    handleCloseClick() {
      this.showConfirmBackDialog = false;
    },
    handleConfirmClick() {
      this.showConfirmBackDialog = false;
      this.$router.replace({
        name: 'Milestones',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        },
      });
    },
    closeShowAddUsersView() {
      this.showAddUsersView = false;
      this.toBeSelectedUser = [];
    },
    changeFilter(filter) {
      this.tableFilter = filter;
    },
    addUsers() {
      this.users.forEach((user) => {
        if (user.toBeSelected) {
          this.$set(user, 'selected', true);
          this.$set(user, 'toBeSelected', false);
        }
      });
    },
    removeUsers() {
      this.users.forEach((user) => {
        if (user.toBeSelected) {
          this.$set(user, 'selected', false);
          this.$set(user, 'toBeSelected', false);
        }
      });
    },
    updateUser(user) {
      const index = this.users.findIndex((item) => item.id === user.id);
      if (index !== -1) {
        this.$set(user, 'selected', this.tableFilter === 'all');
        this.$set(user, 'toBeSelected', false);
      }
    },
    clearAll() {
      this.appliedFilters = {
        roles: [],
        projects: [],
        tags: [],
      };
    },
    applyFilters(filters) {
      this.appliedFilters = filters;
    },
  },
};
</script>

<style lang="scss" scoped>

.calendar-icon {
  position: absolute;
  right: 12px;
  top: 9px;
}

.box-shadow-none {
  box-shadow: none;
}

.calendar-textbox-container {
  position: relative;
}

.back-to-projects {
  display: flex;
  cursor: pointer;
  width: max-content;
}

.search_input {
  width: 100%;

  @media screen and (min-width: 600px) {
    width: 300px;
  }
}

.project-logo {
  border-radius: 50%;
  border: 2px dashed grey;
  width: 150px;
  height: 150px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s;
  background-size: cover;
  background-position: center;
  position: relative;
}

.project-logo:hover {
  border-color: #2196f3;
}

.hovering .edit-icon,
.hovering .delete-icon {
  display: block;
}

.edit-icon,
.delete-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(-30px);
  display: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.delete-icon {
  margin-left: 60px;
}

.project-logo:hover .edit-icon,
.project-logo:hover .delete-icon {
  display: block;
}
</style>
